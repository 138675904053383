import React from 'react';
import img1 from '../../assets/coursesimage/img1.webp'
import p1 from '../../assets/coursesimage/p1.webp'
import c1 from '../../assets/coursesimage/c1.webp'
import f1 from '../../assets/coursesimage/f1.webp'
import d1 from '../../assets/coursesimage/d1.webp'


const CpCourses: React.FC = () => {
  const courses = [
    {
      id: '1',
      title: 'Introduction to AI and Machine Learning',
      description: 'Learn the fundamentals of AI and Machine Learning, including algorithms, data processing, and model creation. Perfect for beginners starting their AI journey.',
      image: { url: img1 },
      enrolledCount: 1200,
      duration: 120,
      price: 50,
      link: 'https://example.com/ai-course-1',
    },
    {
      id: '4',
      title: 'Python Core',
      description: 'Master the basics of Python programming, covering syntax, data structures, and functions. A foundational course for aspiring programmers and data enthusiasts.',
      image: { url: p1},
      enrolledCount: 1500,
      duration: 100,
      price: 40,
      link: 'https://example.com/python-course-1',
    },
    {
      id: '9',
      title: 'Introduction to Cybersecurity',
      description: 'Explore the basics of cybersecurity, including threat identification, network security, and encryption techniques. Essential for beginners aiming for a career in cybersecurity.',
      image: { url: c1},
      enrolledCount: 1800,
      duration: 140,
      price: 70,
      link: 'https://example.com/cyber-course-1',
    },
    {
      id: '11',
      title: 'Full Stack Web Development',
      description: 'Become a full-stack developer by learning front-end and back-end technologies. Cover HTML, CSS, JavaScript, databases, and server-side frameworks.',
      image: { url: f1 },
      enrolledCount: 2200,
      duration: 180,
      price: 80,
      link: 'https://example.com/webdev-course-1',
    },
    {
      id: '13',
      title: 'DevOps Basics for Everyone',
      description: 'Understand DevOps principles, tools, and practices to streamline software development. Suitable for beginners aiming to adopt a DevOps mindset.',
      image: { url: d1 },
      enrolledCount: 1500,
      duration: 120,
      price: 70,
      link: 'https://example.com/devops-course-1',
    },
  ];

  return (
    <div className="container mx-auto pt-32 py-12">
      {/* <h2 className="text-3xl font-semibold text-center mb-8">Computer Programming Courses</h2> */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="bg-white rounded-lg shadow-lg mx-2 my-2 flex flex-col items-start
            relative max-w-sm overflow-hidden cursor-pointer transform transition-transform 
            duration-500 ease-in-out hover:-translate-y-2 hover:scale-105"
            onClick={() => window.open(course.link, '_blank')} // Open enroll link when card is clicked
          >
            <img
              src={course.image.url || 'https://via.placeholder.com/150'}
              alt={course.title}
              className="w-full h-32 object-cover  rounded"
            />
            <h4 className="text-lg font-semibold text-left text-gray-800  p-2">
              {course.title}
            </h4>
            <div className="flex items-center justify-between text-gray-600  p-2 w-full">
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/user-group-man-man.png"
                  alt="Users"
                  className="mr-2"
                />
                <span>{course.enrolledCount || 'N/A'} users</span>
              </div>
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/time.png"
                  alt="Duration"
                  className="mr-2"
                />
                <span>{course.duration || 'N/A'} min</span>
              </div>
            </div>
            <hr className="border-t border-blue-400 " />
            <p className="text-gray-600  text-left p-2">
              {course.description || 'No description available.'}
            </p>
            <div className="flex justify-between items-center p-2 w-full pl-2">
              <span className="w-14 font-semibold text-white rounded-full pl-2" style={{ backgroundColor: '#050ADF' }}>
                ${course.price || 'Free'}
              </span>
              <button
                onClick={() => window.open(course.link, '_blank')}
                className="border-2 rounded-full w-14 font-semibold"
                style={{ color: '#050ADF', borderColor: '#050ADF' }}
              >
                Enroll
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CpCourses;
