import React from 'react';
import p1 from '../../assets/coursesimage/p1.webp'
import p2 from '../../assets/coursesimage/p2.webp'
import p3 from '../../assets/coursesimage/p3.webp'
import p4 from '../../assets/coursesimage/p4.webp'

const PythonCourses: React.FC = () => {
  const courses = [
    {
      id: '1',
      title: 'Python Core',
      description: 'Master the basics of Python programming, covering syntax, data structures, and functions. A foundational course for aspiring programmers and data enthusiasts.',
      image: { url: p1 },
      enrolledCount: 1500,
      duration: 100,
      price: 40,
      link: 'https://example.com/python-course-1',
    },
    {
      id: '2',
      title: 'Python Advanced',
      description: 'Deepen your Python skills with advanced concepts like OOP, decorators, error handling, and more. Ideal for those with basic Python knowledge seeking.',
      image: { url: p2 },
      enrolledCount: 1000,
      duration: 120,
      price: 50,
      link: 'https://example.com/python-course-2',
    },
    {
      id: '3',
      title: 'Python for Data Science',
      description: 'Learn how to use Python for data analysis and visualization. Cover libraries like Pandas, NumPy, and Matplotlib, equipping you with the skills needed for data science projects.',
      image: { url: p3 },
      enrolledCount: 2000,
      duration: 180,
      price: 70,
      link: 'https://example.com/python-course-3',
    },
    {
      id: '4',
      title: 'MITX Statistics and Data Science with Python MicroMasters',
      description: 'Gain a deep understanding of statistics, probability, and data analysis using Python. Perfect for learners pursuing advanced data science skills.',
      image: { url: p4 },
      enrolledCount: 2500,
      duration: 200,
      price: 90,
      link: 'https://example.com/python-course-4',
    },
  ];

  return (
    <div className="container mx-auto pt-32 p-6">
      {/* <h2 className="text-3xl font-semibold text-center mb-8">Python Courses</h2> */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="bg-white rounded-lg shadow-lg mx-2 my-2 flex flex-col items-start
            relative max-w-sm overflow-hidden cursor-pointer transform transition-transform 
            duration-500 ease-in-out hover:-translate-y-2 hover:scale-105"
            onClick={() => window.open(course.link, '_blank')} // Open enroll link when card is clicked
          >
            <img
              src={course.image.url || 'https://via.placeholder.com/150'}
              alt={course.title}
              className="w-full h-32 object-cover  rounded"
            />
            <h4 className="text-lg font-semibold text-left text-gray-800  p-2">
              {course.title}
            </h4>
            <div className="flex items-center justify-between text-gray-600  p-2 w-full">
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/user-group-man-man.png"
                  alt="Users"
                  className="mr-2"
                />
                <span>{course.enrolledCount || 'N/A'} users</span>
              </div>
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/time.png"
                  alt="Duration"
                  className="mr-2"
                />
                <span>{course.duration || 'N/A'} min</span>
              </div>
            </div>
            <hr className="border-t border-blue-400 " />
            <p className="text-gray-600  text-left p-2">
              {course.description || 'No description available.'}
            </p>
            <div className="flex justify-between items-center p-2 w-full pl-2">
              <span className="w-14 font-semibold text-white rounded-full pl-2" style={{ backgroundColor: '#050ADF' }}>
                ${course.price || 'Free'}
              </span>
              <button
                onClick={() => window.open(course.link, '_blank')}
                className="border-2 rounded-full w-14 font-semibold"
                style={{ color: '#050ADF', borderColor: '#050ADF' }}
              >
                Enroll
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PythonCourses;
