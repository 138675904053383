import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface CourseDetailProps {
  _id: string;
  title: string;
  description: string;
  image: {
    url: string;
  };
  category: string;
  subCategory: string[];
  rating: number;
  students: number;
  instructor: string;
  lastUpdated: string;
  language: string;
  price: number;
  enrolledCount: number;
  link: string | null;  // Allow link to be nullable
}

const CourseDetail: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const [course, setCourse] = useState<CourseDetailProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        const response = await axios.get(`https://spark-mind.vercel.app/api/courses/${courseId}`);
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course details:', error);
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [courseId]);

  if (loading) {
    return <div>Loading course details...</div>;
  }

  if (!course) {
    return <div>No course found.</div>;
  }

  return (
    <div className="container mx-auto p-5 pt-28">
      <div className="border border-gray-300 rounded-lg shadow-lg p-5 grid grid-cols-1 md:grid-cols-2">
        <div>
          <h1 className="text-3xl font-bold mb-4">{course.title}</h1>
          <img src={course.image.url} alt={course.title} className="w-full h-auto rounded-lg object-cover" />
        </div>
        <div className="text-gray-700 p-4">
          {/* <p className="text-lg text-yellow-600"><strong className="text-black">Rating:</strong> {course.rating} ★</p> */}
          <p className="text-lg text-blue-700"><strong className="text-black">Price :</strong> {course.price}</p>
          {/* <p className="text-lg text-blue-700"><strong className="text-black">Instructor:</strong> {course.instructor}</p> */}
          <p className="text-lg text-blue-700"><strong className="text-black">Language:</strong> {course.language}</p>
          {/* <p className="text-lg text-blue-700"><strong className="text-black">Last Updated:</strong> {course.lastUpdated}</p> */}
          {/* <p className="text-lg text-blue-700"><strong className="text-black">Students Enrolled:</strong> {course.enrolledCount}</p> */}
          
          {/* Scrollable Description */}
          <div className="bg-gray-100 mt-4 p-4 rounded-lg h-60 overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-2">What you'll learn and about the course</h2>
            <p>{course.description}</p>
          </div>

          {/* Conditionally render the Enroll Now button if link exists */}
          {course.link && (
  <div className="flex items-center pt-4 justify-around">
    <button
      className="mt-4 px-4 sm:px-6 py-2 text-white rounded-full text-sm sm:text-md md:text-lg hover:text-gray-200"
      style={{ backgroundColor: "#050ADF" }}
      onClick={() => course.link && window.open(course.link, '_blank')}  // Ensure course.link is not null
    >
      More Details
    </button>
  </div>
)}

        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
