import React, { useEffect, useState } from 'react';

interface SaleBannerProps {
  onClose: () => void;
}

interface BannerData {
  description: string;
  color: string;
  validTill: string;
  active: boolean;
}

const SaleBanner: React.FC<SaleBannerProps> = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [bannerData, setBannerData] = useState<BannerData | null>(null);
  const [isBannerVisible, setIsBannerVisible] = useState(true); // Track visibility after closing

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch('https://spark-mind.vercel.app/api/banners/');
        const data = await response.json();
        const activeBanner = data.find((banner: BannerData) => banner.active);

        if (activeBanner) {
          setBannerData(activeBanner);
          const endTime = new Date(activeBanner.validTill);
          const updateCountdown = () => {
            const now = new Date();
            const difference = endTime.getTime() - now.getTime();

            if (difference > 0) {
              const days = Math.floor(difference / (1000 * 60 * 60 * 24));
              const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
              const minutes = Math.floor((difference / (1000 * 60)) % 60);
              const seconds = Math.floor((difference / 1000) % 60);

              setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            } else {
              setTimeLeft('Sale has ended');
            }
          };

          const timer = setInterval(updateCountdown, 1000);
          return () => clearInterval(timer);
        } else {
          setIsBannerVisible(false); // Hide banner if inactive
          onClose(); // Notify parent that banner is inactive
        }
      } catch (error) {
        console.error('Failed to fetch banner data:', error);
        setIsBannerVisible(false); // Hide banner if there's an error
        onClose(); // Notify parent of error
      }
    };

    fetchBannerData();
  }, []);

  const handleClose = () => {
    setIsBannerVisible(false); // Hide banner when closed
    onClose(); // Notify parent to update the state
  };

  // If the banner is inactive or closed, return null
  if (!bannerData || !isBannerVisible) return null;

  return (
    <div
      className="text-center py-2 px-4 text-black fixed top-0 left-0 w-full z-50 shadow-md"
      style={{ backgroundColor: bannerData.color }}
    >
      <div className="text-xs sm:text-base md:text-lg p3-1 pr-3 ">
        {bannerData.description}
      </div>
      <div>
        <strong>Ends in {timeLeft}</strong>
      </div>
      <button
        onClick={handleClose}
        className="absolute border border-red-500 rounded-full w-6 right-1 top-1 text-red-500 font-semibold"
      >
        X
      </button>
    </div>
  );
};

export default SaleBanner;
