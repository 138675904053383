import React, { useState } from 'react';
import Carousel from '../components/Carousel';
import TestimonialSection from '../components/TestimonialSection';
import WorkshopSection from '../components/WorkshopSection';
import Popup from '../components/Popup';
import CourseSection from '../components/CourseSection';
import PopularTopics from '../components/PopularTopics';

const Home: React.FC = () => {
  const [showTopCategories, setShowTopCategories] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup when the user clicks anywhere
  };

  const handleNoActiveWorkshops = () => {
    setShowTopCategories(true); // Show TopCategories if no active workshops
  };

  return (
    <div>
       {/* {showPopup && <Popup onClose={handleClosePopup} />} */}
      <Carousel />
      <WorkshopSection onNoActiveWorkshops={handleNoActiveWorkshops} />
      <CourseSection/>
      <PopularTopics/>
      {/* {showTopCategories && <TopCategories />} */}
      <TestimonialSection />
    </div>
  );
};

export default Home;
