import React, { useEffect, useState } from 'react';
import { fetchCourses } from '../services/CourseService'; // Replace with your actual service
import less from '../assets/Course Slection Icon/Lesson-01.svg'; // Add correct import paths for icons
import stu from '../assets/Course Slection Icon/Person-01.svg'; 
import rightCarrow from '../assets/craousel/Circle Arrow Icon-01.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Categories: React.FC = () => {
  const [courses, setCourses] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const getCourses = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch courses', error);
        setLoading(false);
      }
    };

    getCourses();
  }, []);

  const categories = Array.from(new Set(courses.map(course => course.category.name)));

  const filteredCourses = selectedCategory
    ? courses.filter(course => course.category.name === selectedCategory)
    : courses;

  const handleCourseClick = (courseId: string) => {
    navigate(`/courses/${courseId}`); // Navigate to the course page
  };

  const handleHover = () => {
    // Add any hover logic if needed
  };

  const handleMouseLeave = () => {
    // Add any hover leave logic if needed
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-28 px-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredCourses.map((course) => (
          <div
            key={course._id} // Make sure this is consistent with your API data structure
            onClick={() => handleCourseClick(course._id)} // Open course on card click
            className="bg-white rounded-3xl w-full lg:w-80 h-[25rem] lg:h-[90%] mt-3 mb-6 ml-2 shadow-lg overflow-hidden border-2 border-gray-200 relative cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl hover:z-10"
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={course.image?.url || "https://via.placeholder.com/150"}
              alt={course.title}
              className="w-full h-32 lg:h-48 object-cover"
            />
            <div className="p-4">
              <div className="flex justify-between items-center rounded-lg">
                {/* Render category name instead of the entire object */}
                <span className="text-lg font-semibold text-black bg-gray-300 px-2 py-1 rounded">
                  {course.category?.name}
                </span>
                <span className="text-orange-500 text-lg font-bold">${course.price || "Free"}</span>
              </div>
              <div className="">
                <h3 className="text-lg font-bold text-gray-800 mt-2">{course.title}</h3>
              </div>
              <div className="flex justify-between mt-2 text-sm text-gray-600">
                <div className="flex items-center">
                  <img className="w-5 pr-1" src={less} alt="Lessons" />
                  {course.lessons || "20"} Lessons
                </div>
                <div className="flex items-center">
                  <img className="w-8" src={stu} alt="Students" />
                  {course.enrolledCount || "N/A"} Students
                </div>
              </div>
            </div>
            <div className="flex justify-end pt-3 pr-6 mb-2 lg:mb-6 text-orange-500">
              <button className="text-lg">
                <img src={rightCarrow} alt="Next" className="w-6 h-6 bg-orange-500 rounded-full" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
